import React from "react";
import Title from "../../../components/Title/Title";

const SocialActivities2025page = () => {
    return (
        <div>
            <Title>Social Activities</Title>

            <h3>Tue, 11 Apr: Bar Night</h3>
            Casual pub evening, starting from 20:00 at <a href="https://goo.gl/maps/fnm99qApG1DZwzba6">Freistil Taproom</a>.
            <ul>
                <li>For those interested, meet already at 18:30 at <a href="https://goo.gl/maps/Nr3YcJ81cvqoFAdd7">Neckarmüller Brewery</a> for a beer and some dinner.</li>
                <li>They also serve a limited selection of plates at Freistil.</li>
                <li>Later in the evening, we may crawl to a couple of other bars in the center.</li>
            </ul>
            <h3>Wed, 12 Apr: Conference Dinner</h3>
            Official conference dinner, starting from 18:30 at <a href="https://goo.gl/maps/JerqhqB2b31odXpM9">Museum</a> (upstairs from <a href="https://goo.gl/maps/d4drHzSSeGuv3jUa7">1821</a>).
            <ul>
                <li>18:30--19:30: Reception with bar (drinks paid directly by participants)</li>
                <li>From 19:30: Buffet style dinner</li>
                <li>Dress code: wear whatever you are comfortable with</li>
                <li>You may need to pay for alcoholic beverages during dinner</li>
            </ul>
            <h3>Thu, 13 Apr: Hike</h3>
            Group hike to the neighbouring village Bebenhausen.
            <ul>
                <li>We will meet at and leave from the conference venue at 14:30. </li>
                <li>The destination is the <a href="https://goo.gl/maps/6CoVWcDMWxwkvxY97">12th century monastery</a> in Bebenhausen.</li>
                <li>We recommend wearing shoes suitable for hiking.</li>
                <li>Duration: ca 2.5h (45min to Bebenhausen, mostly downhill + time to walk around + 1h back, mostly uphill incl. a somewhat steep part).</li>
                <li>Whoever wants stays to explore the monastery and the palace (tickets on their own cost) need to find their way back.
                    The rest of the people continue hiking on <a href="https://www.outdooractive.com/en/route/hiking-route/schwaebische-alb/bebenhausen-waelder-und-wiesen/166318974/#dm=1">this trail</a>.
                </li>
                <li>
                    <strong>Recommendations:</strong> Download the <a href="https://www.outdooractive.com/en/mobile-app.html">outdoor active app</a>  on your phone and save <a href="https://www.outdooractive.com/en/route/hiking-route/schwaebische-alb/bebenhausen-waelder-und-wiesen/166318974/#dm=1">the trail</a>.
                    We plan to move as a group but just in case someone moves apart.
                </li>
            </ul>
            <strong>If you have questions about any of the above activities, just ask the social co-chairs Atalanti and Julius during the conference.</strong>

        </div>
    )
}

export default SocialActivities2025page
