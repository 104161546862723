import React from "react";
import Title from "../../../components/Title/Title";

const ZoomLink = ({ topic, time, link, meetingId, passcode, localNumberLink }) => (
    <div>
        <h5>{topic}</h5>
        <p style={{ marginTop: 0, marginBottom: "0.2em" }}>Time: {time}</p>
        <p style={{ marginTop: 0, marginBottom: "0.2em" }}>Join Zoom Meeting:</p>
        <p style={{ marginTop: 0, marginBottom: "0.2em" }}><a href={link}>{link}</a></p>
        <p style={{ marginTop: 0, marginBottom: "0.2em" }}>Meeting ID: {meetingId}</p>
        <p style={{ marginTop: 0, marginBottom: "0.2em" }}>Passcode: {passcode}</p>
        <p style={{ marginTop: 0, marginBottom: "0.2em" }}><a href={localNumberLink}>Find your local number</a></p>
    </div>
);


const papers = [
    {
        title: "Poster I.1. Sample-Specific Root Causal Inference with Latent Variables",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day1/CLeaR_EEL_Poster.pdf"
    },
    {
        title: "Poster I.2. Causal Discovery for Non-stationary Non-linear Time Series Data Using Just-In-Time Modeling",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day1/CLeaR2023PosterComplete.pdf"
    },
    {
        title: "Poster I.3. Causal Models with Constraints",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day1/CLeaR_poster-5.pdf"
    },
    {
        title: "Poster I.4. Non-parametric identifiability and sensitivity analysis of synthetic control models",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day1/CLeaR2023_%20Spotify-7.pdf"
    },
    {
        title: "Poster I.6. Estimating long-term causal effects from short-term experiments and long-term observational data with unobserved confounding",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day1/CLeaR2023_Poster_VanGoffrier.pdf"
    },
    {
        title: "Poster I.7. Learning Causal Representations of Single Cells via Sparse Mechanism Shift Modeling",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day1/Learning_Causal_Representations_of_Single_Cells_via_Sparse_Mechanism_Shift_Modeling.pdf"
    },
    {
        title: "Poster I.9. Instrumental Processes Using Integrated Covariances",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day1/posterI10.pdf"
    },
    {
        title: "Poster II.1. Branch-Price-and-Cut for Causal Discovery",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day2/poster_1.pdf"
    },
    {
        title: "Poster II.3. Beyond the Markov Equivalence Class: Extending Causal Discovery under Latent Confounding",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day2/poster_CLeaR_2023_final.pdf"
    },
    {
        title: "Poster II.4. Learning Conditional Granger Causal Temporal Networks",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day2/Learning_Conditional_Granger_Causal_Temporal_Networks.pdf"
    },
    {
        title: "Poster II.5. Practical Algorithms for Orientations of Partially Directed Graphical Models",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day2/Poster_Practical_Algorithms_for_Orientations_of_Partially_Directed_Graphical_Models.pdf"
    },
    {
        title: "Poster II.6. Enhancing Causal Discovery from Robot Sensor Data in Dynamic Scenarios",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day2/Postercopy.pdf"
    },
    {
        title: "Poster II.7. Leveraging Causal Graphs for Blocking in Randomized Experiments",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day2/CleaR2023-poster.pdf"
    },
    {
        title: "Poster II.8. Generalizing Clinical Trials with Convex Hulls",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day2/CLeaR_OCH_Poster.pdf"
    },
    {
        title: "Poster II.9. Backtracking Counterfactuals",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day2/Poster_Backtracking_Counterfactuals.pdf"
    },
    {
        title: "Poster II.10. Stochastic Causal Programming for Bounding Treatment Effects",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day2/CLeaR_poster_SCP.pdf"
    },
    {
        title: "Poster III.1. A Meta-Reinforcement Learning Algorithm for Causal Discovery",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day3/23_meta_rl_cd_clear_poster.pdf"
    },
    {
        title: "Poster III.2. Causal Inference Despite Limited Global Confounding via Mixture Models",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day3/CLeaR_2023_Poster_Vertical_Final.pdf"
    },
    {
        title: "Poster III.3. Causal Triplet: An Open Challenge for Intervention-centric Causal Representation Learning",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day3/poster_causal_triplet.pdf"
    },
    {
        title: "Poster III.4. Image-based Treatment Effect Heterogeneity",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day3/ImageHeterogeneity_clear.pdf"
    },
    {
        title: "Poster III.5. Causal Learning through Deliberate Undersampling",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day3/CLeaR_poster_Soloveva.pdf"
    },
    {
        title: "Poster III.6. Influence-Aware Attention for Multivariate Temporal Point Processes",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day3/Influence-Aware_Attention_for_Multivariate_Temporal_Point_Processes_CLeaR23.pdf"
    },
    {
        title: "Poster III.7. Evaluating Temporal Observation-Based Causal Discovery Techniques Applied to Road Driver Behaviour",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day3/CLeaR23_causaldiscovery_poster.pdf"
    },
    {
        title: "Poster III.8. Directed Graphical Models and Causal Discovery for Zero-Inflated Data",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day3/ZeroDAG_poster_final.pdf"
    },
    {
        title: "Poster III.10. Local Dependence Graphs for Discrete Time Processes",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day3/clear-poster-niemiro_rajkowski.pdf"
    },
    {
        title: "Poster IV.1. On Discovery of Local Independence over Continuous Variables via Neural Contextual Decomposition",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day4/CSSI_CLeaR2023_poster.pdf"
    },
    {
        title: "Poster IV.3. Can Active Sampling Reduce Causal Confusion in Offline Reinforcement Learning?",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day4/CleaR.pdf"
    },
    {
        title: "Poster IV.4. Causal Inference with Non-IID Data under Model Uncertainty",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day4/poster_3.pdf"
    },
    {
        title: "Poster IV.5. Factual Observation Based Heterogeneity Learning for Counterfactual Prediction",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day4/poster.pdf"
    },
    {
        title: "Poster IV.6. On the Interventional Kullback-Leibler Divergence",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day4/On_the_IKL_div_poster.pdf"
    },
    {
        title: "Poster IV.7. Unsupervised Object Learning via Common Fate",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day4/poster_tangemann_et_al.pdf"
    },
    {
        title: "Poster IV.8. Distinguishing Cause from Effect on Categorical Data: The Uniform Channel Model",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day4/Figueiredo_CleaR_2023_Poster.pdf"
    },
    {
        title: "Poster IV.9. Jointly Learning Consistent Causal Abstractions Over Multiple Interventional Distributions",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day4/poster1.pdf"
    },
    {
        title: "Poster IV.10. Causal Abstraction with Soft Interventions",
        downloadLink: "https://www.cclear.cc/2023/Posters/Day4/CausalAbsractionWithSoftInterventions.pdf"
    },
];

class ZoomLinks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            password: "",
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        // This is a dummy password, replace this with your own password.
        const correctPassword = "causality2023_p";
        if (this.state.password === correctPassword) {
            this.setState({ isAuthenticated: true });
        } else {
            alert("Incorrect password");
        }
    }

    handleChange(event) {
        this.setState({ password: event.target.value });
    }

    render() {
        if (this.state.isAuthenticated) {
            return (
                <div>
                    <p>Registered participants can attend keynotes and oral presentations (see <a href="https://www.cclear.cc/2023/FullAgenda">schedule</a>) remotely. Please connect to this Zoom meeting:</p>
                    <ZoomLink
                        topic="CLeaR 2023 Zoom room"
                        time="Apr 11, 2023 01:00 PM Amsterdam, Berlin, Rome, Stockholm, Vienna"
                        link="https://us06web.zoom.us/j/84651241114?pwd=YVJnVkExVmYzUE1zVFYvMC9jVkFkdz09"
                        meetingId="846 5124 1114"
                        passcode="132928"
                        localNumberLink="https://us06web.zoom.us/u/keCoplcCF"
                    />
                    <p>This link is used for all keynotes and orals. Note that in-person poster sessions, the townhall, panel discussion, and social activities will not be streamed.</p>
                    <p>When participating remotely, please mute yourself (except when asking questions). Feel free to ask questions either in the chat or by raising your hand in Zoom and asking them over video.</p>
                    <p>Virtual posters can be found below.</p>
                    <table>
                        <thead>
                            <tr>
                                <th>Paper title</th>
                                <th>Download link</th>
                            </tr>
                        </thead>
                        <tbody>
                            {papers.map((paper) => (
                                <tr key={paper.title}>
                                    <td>{paper.title}</td>
                                    <td><a href={paper.downloadLink} target="_blank" rel="noopener noreferrer">Download</a></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            );
        } else {
            return (
                <form onSubmit={this.handleSubmit}>
                    <h5>Enter password to access Zoom links and posters</h5>
                    <label>
                        Password:
                        <input type="password" value={this.state.password} onChange={this.handleChange} />
                    </label>
                    <button type="submit">Submit</button>
                </form>
            );
        }
    }
}



const OnlineSchedulepage = () => {
    return (
        <div>
            <Title>Online Schedule</Title>
            <ZoomLinks />
        </div>
    )
}

export default OnlineSchedulepage

