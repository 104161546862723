import React from "react";
import Title from "../../../components/Title/Title";

const CodeConduct2025page = () => {
    return (
        <div>
            <Title>Conflicts of Interest</Title>
            <p>This page defines what constitutes a conflict of interest for the CLeaR 2025 review process. </p>
            <p>If you are an author, reviewer, or area chair, please make sure to create or update your OpenReview profile. You will be asked to declare two types of conflicts---domain conflicts and personal conflicts.  Both types are declared by filling out appropriate sections of your OpenReview profile, as described below.</p>
            <ul>
                <li><b>Domain conflicts (entered in Education & Career History)</b></li>
                Please ensure that this section accurately represents your domain conflicts for (at least) the last three years. When you enter a domain conflict, none of your submissions will be visible to reviewers or area chairs who have also entered this domain conflict.  Only the last three years of your and their Education & Career History will be used.
                <li><b>Personal conflicts (entered in Advisors, Relations & Conflicts)</b></li>
                You should also enter your personal conflicts, including (1) family or close personal relationship, (2) Ph.D. advisee/advisor relationship, and (3) current, frequent, or recent collaboration (including internships) within the past three years.
                If you have any questions about special circumstances not discussed above, please contact program chairs at <a href="mailto:chairs.clear2025@gmail.com">chairs.clear2025@gmail.com</a>.
            </ul>
        </div>
    );
};

export default CodeConduct2025page;
