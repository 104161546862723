import React from "react";
import Title from "../../../components/Title/Title";
import PaperTable from "./components/PaperTable/PaperTable";
import acceptedPapers_oral from "../../../common/constants/acceptedPapers_oral2025";
import acceptedPapers_poster from "../../../common/constants/acceptedPapers_poster2025";
import acceptedPapers_best from "../../../common/constants/acceptedPapers_best2025";
const AcceptedPapers2025page = () => {
    return (
        // <div>
        //   <Title>Accepted Papers</Title>
        //   TBD
        // </div>
        <div>
            <Title>Accepted Papers</Title>
            <h5><b>Best Paper award</b></h5>
            <PaperTable array={acceptedPapers_best} />
            <h5><b>Oral</b></h5>
            <PaperTable array={acceptedPapers_oral} />
            <h5><b>Poster</b></h5>
            <PaperTable array={acceptedPapers_poster} />
        </div>
    );
};

export default AcceptedPapers2025page;
