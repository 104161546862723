import React from 'react'
import Title from '../../../components/Title/Title'
import OCTable from './components/OCTable/OCTable'
import { array2025 } from '../../../common/constants/organizingCommittee'


const OrganizingCommittee2025page = () => {
    return (
        <div>
            <Title>Organizing Committee</Title>
            <OCTable array={array2025} />
        </div>
    )
}

export default OrganizingCommittee2025page
