import React from "react";
import Title from "../../../components/Title/Title";

const ProgramCommittee2025page = () => {
    return (
        <div>
            <Title>Program Committee</Title>

            TBD
        </div>
    )
}

export default ProgramCommittee2025page
