import React from "react";
import Title from "../../../components/Title/Title";
import './SponsorsPage.scss';

const Sponsors2025page = () => {
    return (
        <div>
            <Title>Sponsors</Title>
            <center>
                <p> TBA </p>
            </center>
        </div>
    )
}

export default Sponsors2025page
