import React from "react";
import Title from "../../../components/Title/Title";
import PaperTable from "./components/PaperTable/PaperTable";
import acceptedDatasets from "../../../common/constants/acceptedDatasets_2025";

const AcceptedDatasets2025page = () => {
    return (
        // <div>
        //   <Title>Accepted Papers</Title>
        //   TBD
        // </div>
        <div>
            <Title>Accepted Datasets</Title>
            <PaperTable array={acceptedDatasets} />
        </div>

    );
};

export default AcceptedDatasets2025page;
